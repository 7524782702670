import CustomerComplaintForm from '@/components/customerComplaintForm/index';
import { Layout } from '@/components/Layout/layout';

const Feedback = () => {
  return (
    <>
      <Layout>
        <CustomerComplaintForm /> 
      </Layout>
    </>
  );
};

// Feedback.Layout = Layout;

export default Feedback;
