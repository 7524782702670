/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';
import * as Toast from '@radix-ui/react-toast';
import { css, keyframes } from '@emotion/react';
import { useTheme } from 'next-themes';
import { ToastContext } from './ToastContext';

export const ThemedToast: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('默认消息');
  const [variant, setVariant] = useState<'success' | 'warning' | 'error'>('success');
  
  const { theme } = useTheme();

  // 定义主题颜色映射
  const themeColors = {
    success: {
      bg: theme === 'dark' 
        ? '#132F21'    // 深色模式下的绿色背景
        : '#ECFDF5',   // 浅色模式下的绿色背景
      text: theme === 'dark'
        ? '#4ADE80'    // 深色模式下的文字颜色
        : '#15803D'    // 浅色模式下的文字颜色
    },
    warning: {
      bg: theme === 'dark'
        ? '#2E2516'    // 深色模式下的黄色背景
        : '#FEFCE8',   // 浅色模式下的黄色背景
      text: theme === 'dark'
        ? '#FACC15'    // 深色模式下的文字颜色
        : '#A16207'    // 浅色模式下的文字颜色
    },
    error: {
      bg: theme === 'dark'
        ? '#2A1619'    // 深色模式下的红色背景
        : '#FEF2F2',   // 浅色模式下的红色背景
      text: theme === 'dark'
        ? '#F87171'    // 深色模式下的文字颜色
        : '#B91C1C'    // 浅色模式下的文字颜色
    }
  };

  // 定义动画
  const hide = keyframes({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  });

  const slideIn = keyframes({
    from: {
      transform: 'translateX(calc(100% + 24px))',
    },
    to: {
      transform: 'translateX(0)',
    },
  });

  const slideOut = keyframes({
    from: {
      transform: 'translateX(0)',
    },
    to: {
      transform: 'translateX(calc(100% + 24px))',
    },
  });

  // 样式定义
  const toastStyles = {
    root: css({
      padding: '16px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      fontSize: '14px',
      boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.05)',
      backgroundColor:
        variant === 'success'
          ? themeColors.success.bg
          : variant === 'warning'
          ? themeColors.warning.bg
          : themeColors.error.bg,
      color:
        variant === 'success'
          ? themeColors.success.text
          : variant === 'warning'
          ? themeColors.warning.text
          : themeColors.error.text,
      '&[data-state="open"]': {
        animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
      },
      '&[data-state="closed"]': {
        animation: `${hide} 100ms ease-in`,
      },
      '&[data-swipe="move"]': {
        transform: 'translateX(var(--radix-toast-swipe-move-x))',
      },
      '&[data-swipe="cancel"]': {
        transform: 'translateX(0)',
        transition: 'transform 200ms ease-out',
      },
      '&[data-swipe="end"]': {
        animation: `${slideOut} 100ms ease-out`,
      },
    }),
    viewport: css({
      position: 'fixed',
      top: 24,
      right: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '300px',
      maxWidth: '100vw',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      zIndex: 1000,
    }),
    title: css({
      fontWeight: 600,
      marginBottom: '4px',
    }),
    description: css({}),
  };

  const showToast = useCallback((type: 'success' | 'warning' | 'error', content: string) => {
    setVariant(type);
    setMessage(content);
    setOpen(true);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast.Provider swipeDirection="right" duration={2000}>
        <Toast.Root 
          css={toastStyles.root} 
          open={open} 
          onOpenChange={setOpen}
        >
          <div>
            <Toast.Title css={toastStyles.title}>
              {variant === 'success' ? 'Success' : variant === 'warning' ? 'Warning' : 'Error'}
            </Toast.Title>
            <Toast.Description css={toastStyles.description}>
              {message}
            </Toast.Description>
          </div>
        </Toast.Root>
        <Toast.Viewport css={toastStyles.viewport} />
      </Toast.Provider>
    </ToastContext.Provider>
  );
};
